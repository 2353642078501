<template>
    <el-dialog :title="title" width="60%" :close-on-click-modal="false" :visible.sync="visible">
        <el-form size="small" :model="personalForm" ref="personalForm" label-width="100px" @keyup.enter.native="doSubmit()" v-loading="loading" :disabled="method==='view'" @submit.native.prevent>
            <el-form-item label="头像：" prop="image">
                <HeadUpload :image="personalForm.image" @getImgList="getImgList"/>
            </el-form-item>
            <el-form-item label="姓名：" prop="name"
                          :rules="[{ required: true, whitespace: true, message: '请输入姓名', trigger: 'blur' }]">
                <el-input v-model="personalForm.name" placeholder="请输入姓名（必填项）" maxlength="20"
                          auto-complete="new-password"></el-input>
            </el-form-item>
            <el-form-item label="手机号：" prop="mobilePhone"
                          :rules="[{ required: true, message: '请输入手机号', trigger: 'blur' },{validator:validator.isMobile, trigger:'blur'}]">
                <el-input v-model.number="personalForm.mobilePhone" placeholder="如修改手机号，请输入手机号码"
                          maxlength="11" auto-complete="new-password"></el-input>
            </el-form-item>
            <el-form-item v-if="method == 'add'" label="密码：" prop="password"
                          :rules="[{ required: true, message: '请输入密码', trigger: 'blur' },{validator:validator.isPwd, trigger:'blur'}]">
                <el-input show-password v-model="personalForm.password" placeholder="请输入密码（8-20位包含大小写字母，数字，特殊符号例如@）" maxlength="20"
                          auto-complete="new-password"></el-input>
            </el-form-item>
            <el-form-item label="城市：" :rules="[{ required: true, message: '请选择城市', trigger: 'blur' }]">
                <el-cascader
                        v-model="cityId"
                        :options="cityOpt" :props="{ value: 'id', label: 'name' }"
                        @change="changeCity" style="width: 100%"></el-cascader>
            </el-form-item>
            <el-form-item label="医院：" prop="hospitalId"
                          :rules="[{ required: true, message: '请选择医院', trigger: 'blur' }]">
                <el-select v-model="personalForm.hospitalId" placeholder="请选择" style="width: 100%" @change="changeHospital">
                    <el-option
                            v-for="item in hospitalOpt"
                            :key="item.id"
                            :label="item.hospitalName"
                            :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="科室：" prop="department"
                          :rules="[{ required: true, message: '请选择科室', trigger: 'blur' }]">
                <el-select v-model="personalForm.department" placeholder="请选择" style="width: 100%">
                    <el-option
                            v-for="item in departmentOpt"
                            :key="item.id"
                            :label="item.departmentName"
                            :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="性别：" prop="sex">
                <el-radio-group v-model="personalForm.sex">
                    <el-radio :label="0">保密</el-radio>
                    <el-radio :label="1">男</el-radio>
                    <el-radio :label="2">女</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="邮箱：" prop="email" :rules="[{validator:validator.isEmail, trigger:'blur'}]">
                <el-input v-model="personalForm.email" placeholder="邮箱（xxxxxxx@xxx.xxx）" maxlength="30"
                          auto-complete="new-password"></el-input>
            </el-form-item>
            <el-form-item label="电话：" prop="telPhone"
                          :rules="[{validator:validator.isPhone, trigger:'blur'}]">
                <el-input v-model="personalForm.telPhone" placeholder="请输入电话（xxxx-xxxxxxx）"
                          maxlength="13" auto-complete="new-password"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="visible = false">关闭</el-button>
      <el-button size="small" type="primary" v-if="method != 'view'" @click="doSubmit()" v-noMoreClick>确定</el-button>
    </span>
    </el-dialog>
</template>

<script>
    import HeadUpload from "@/components/headUpload"

    export default {
        data() {
            return {
                visible: false,
                loading: false,
                title: '',
                method: '',
                // 个人信息
                personalForm: {
                    id: '',
                    image: '',
                    name: '',
                    sex: 0,
                    mobilePhone: '',
                    password: '',
                    email: '',
                    telPhone: '',
                    hospitalId: '',
                    department: '',
                },
                cityId: ['1277876432646750210', '738049426362204160'],
                cityOpt: [],
                hospitalOpt: [],
                departmentOpt: [],
            }
        },
        components: {
            HeadUpload
        },
        methods: {
            // 初始化数据
            init(method, row) {
                this.getCity()
                this.method = method
                if (method === 'add') {
                    this.title = `新建用户`
                } else if (method === 'edit') {
                    this.title = '修改用户'
                } else if (method === 'view') {
                    this.title = '查看用户'
                }
                this.visible = true
                this.$nextTick(() => {
                    if(method != 'add') {
                        this.recover(this.personalForm, row);
                    } else {
                        this.$set(this.personalForm, 'password', '')
                        this.$refs.personalForm.resetFields();
                    }
                })
            },
            changeCity() {
                this.personalForm.hospitalId = ''
                this.personalForm.department = ''
                this.getHospital()
            },
            changeHospital() {
                this.personalForm.department = ''
                this.getDepartment()
            },
            // 获取城市
            getCity() {
                this.$axios(this.api.order.getSysArea).then(res => {
                    if (res.status) {
                        this.cityOpt = res.data
                        this.cityOpt[0].children.map(item => {
                            if(!item.children.length) {
                                delete item.children;
                            }
                        })
                        this.getHospital()
                    }
                })
            },
            // 获取医院
            getHospital() {
                this.$axios(this.api.order.getMingenHospitalByCityId, {cityId: this.cityId[1]}).then(res => {
                    if (res.status) {
                        this.hospitalOpt = res.data
                        this.getDepartment()
                    }
                })
            },
            // 获取科室
            getDepartment() {
                this.$axios(this.api.order.getAllMingenDepartment, {hospitalId: this.personalForm.hospitalId}).then(res => {
                    if (res.status) {
                        this.departmentOpt = res.data
                    }
                })
            },
            // 表单提交
            doSubmit() {
                this.$refs['personalForm'].validate((valid) => {
                    if (valid) {
                        this.loading = true
                        if(this.method === 'add') {  // 新增
                            this.$axios(this.api.auth.sysuserSave, this.personalForm, 'put').then(data => {
                                this.loading = false
                                if (data && data.status) {
                                    this.$message.success(data.msg)
                                    this.visible = false
                                    this.$emit('refreshDataList')
                                } else {
                                    this.$message.error(data.msg)
                                }
                            })
                        } else if(this.method === 'edit') {  // 修改
                            this.personalForm.type = 2
                            delete this.personalForm.password;
                            this.$axios(this.api.auth.sysuserUpdateById, this.personalForm, 'post').then(data => {
                                this.loading = false
                                if (data && data.status) {
                                    if(JSON.parse(sessionStorage.getItem('userInfo')).id == this.personalForm.id) {
                                        this.$store.commit('user/updateUser', data.data)
                                    }
                                    this.visible = false
                                    this.$message.success(data.msg)
                                    this.$emit('refreshDataList')
                                }else{
                                    this.$message.error(data.msg)
                                }
                            })
                        }
                    }
                })
            },
            // 获取用户头像
            getImgList(data) {
                this.personalForm.image = data
            },
        }
    }
</script>
